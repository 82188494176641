import { render, staticRenderFns } from "./ScheduleOrdersActions.vue?vue&type=template&id=54b7f053&scoped=true"
import script from "./ScheduleOrdersActions.vue?vue&type=script&lang=js"
export * from "./ScheduleOrdersActions.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "54b7f053",
  null
  
)

export default component.exports